import * as React from 'react'
import * as ReactDOM from 'react-dom'

import { AuthProvider } from '@/context/AuthProvider'
import { createBrowserRouter, RouterProvider } from "react-router-dom"

import { Layout } from './layout'
import { Profile } from '@/components/Profile'
import Patients from '@/components/Patients'
import PatientEvents from '@/components/PatientEvents'
import MetabaseEmbed from '@/components/MetabaseEmbed'
import Home from '@/components/Home'
import  theme  from '@/utils/PharmaviewTheme'
import { ThemeProvider } from '@mui/material/styles';

export const router = createBrowserRouter([
    {
        path: "/",
        element: <Layout />,
        children: [
            { path: "/", element: <Home />, },
            { path: "/profile", element: <Profile />, },
            { path: "/patients", element: <Patients />, },
            { path: "/patients/:entity_id/events", element: <PatientEvents />, },
            { path: "/dashboard/:dashboard_id", element: <MetabaseEmbed />, },
        ]
    },
]);

ReactDOM.createRoot(document.getElementById("app")).render(
    <React.StrictMode>
        <AuthProvider>
            <ThemeProvider theme={theme}>
                <RouterProvider router={router} />
            </ThemeProvider>
        </AuthProvider>
    </React.StrictMode>
);
