import React from 'react';
import { Outlet } from 'react-router-dom';
import { TopMenu } from '@/components/topMenu';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';

export const Layout = () => {
    return (
        <>
            <CssBaseline />
            <Box component="main" sx={{display: 'flex' , flexDirection: 'column', padding: '0 2em' }}>
                <Outlet />
            </Box>
            <TopMenu />
        </>
    );
};
