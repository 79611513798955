import * as React from 'react';
import { useAPI } from '@/context/AuthProvider';
import { useParams } from 'react-router-dom';
import Loading from '@/components/Loading';

export default function MetabaseEmbed() {
    const { dashboard_id } = useParams();
    const [metabaseIframeUrl, isLoading, error] = useAPI(`/api/v1/get-metabase-iframe-url/${dashboard_id}`);

    if (isLoading) return <Loading />;
    if (error) return <p>Error: {error}</p>;
    if (!metabaseIframeUrl) return <p>No iframe love</p>;

    return (
      <iframe
        src={metabaseIframeUrl}
        style={{ 
          width: '100vw', 
          height: 'calc(100vh - 4.5em)', 
          border: 'none', 
          margin: '-.5em -2em 0'
        }}
        title="Metabase Embed"
      ></iframe>
    );
}
