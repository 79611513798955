import React, { useEffect, createContext, useState, useContext } from 'react';
import Keycloak from 'keycloak-js';
import Loading from '@/components/Loading';

const AuthContext = createContext();
AuthContext.displayName = 'AuthContext';

export function useAPI(path, query = {}) {
    const [data, setData] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { keycloak } = useAuth();

    useEffect(() => {
    if (!keycloak) return;

    const fetchData = async () => {
      try {
        await keycloak.updateToken(5)

        const response = await fetch(path, {
          query: query,
          headers: {
            Authorization: `Bearer ${keycloak.token}`
          }
        });

        const result = await response.json();
        setData(result);
        setLoading(false);
      } catch (e) {
        console.error(e);
        setError(e);
      }
    };

    fetchData();
  }, [keycloak]);

    return [data, isLoading, error];
}

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within a AuthProvider');
    }
    return context;
};

export const AuthProvider = ({ children }) => {
    const [keycloak, setKeycloak] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        const initKeycloak = async () => {
            const kc = new Keycloak('/keycloak.json');
            try {
                const authenticated = await kc.init({
                    onLoad: "login-required",
                    checkLoginIframe: false,
                    scope: "openid roles"
                });
                setKeycloak(kc);
                setIsAuthenticated(authenticated);
                console.log(authenticated ? 'authenticated' : 'not authenticated');
                if (authenticated && kc.tokenParsed && kc.tokenParsed.email) {
                    // set user email on window for Matomo tracking
                    const _pharmaview = window._pharmaview = window._pharmaview || {};
                    _pharmaview.user = _pharmaview.user || {};
                    _pharmaview.user.email = kc.tokenParsed.email;
                    console.log('User email set for Matomo tracking:', _pharmaview.user.email);
                }
            } catch (e) {
                console.error(e);
                throw e;
            }
        };

        initKeycloak();
    }, []);

    if (!keycloak) {
        return <Loading />;
    }

    return (
        <AuthContext.Provider value={{ keycloak, isAuthenticated }}>
            {children}
        </AuthContext.Provider>
    );
};
