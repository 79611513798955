/**
 * @see https://mui.com/material-ui/react-app-bar/
 */
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MedicationIcon from '@mui/icons-material/Medication';
import Divider from '@mui/material/Divider';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '@/context/AuthProvider';
import { isPharmaviewGroup } from '@/utils/authUtils';


function TopMenu() {
  const navigate = useNavigate();
  const { keycloak } = useAuth();
  const token = keycloak.tokenParsed;

  const onLogoutClick = () => {
    const keycloakUrl = keycloak.authServerUrl;
    const realm = keycloak.realm;
    const clientId = keycloak.clientId;
    const encodedRedirectUri = encodeURIComponent(window.location.origin);

    const logoutUrl = `${keycloakUrl}/realms/${realm}/protocol/openid-connect/logout` +
                      `?client_id=${clientId}` +
                      `&post_logout_redirect_uri=${encodedRedirectUri}`;
    window.location.href = logoutUrl;
  }

    const navItems = [
        {text:'Patients', onClick: () => navigate('/patients'), visible: () => isPharmaviewGroup()}, 
        {text:'Profile', onClick: () => navigate('/profile'), visible: true}, 
        {text:'Logout', onClick: onLogoutClick, visible: true}
    ];

  return (
    <AppBar position="fixed">
      <Toolbar>
        <MedicationIcon sx={{ display: 'flex', mr: 1, pl: 2, width: '45px' }} />
        <Typography
          variant="h6"
          noWrap
          component="a"
          onClick={() => navigate('/')}
          sx={{
            mr: 2,
            display: 'flex',
              color: 'white',
            fontWeight: 700,
            textDecoration: 'none',
            cursor: 'pointer',
          }}
        >
            PharmaView
        </Typography>
          <Divider />
          <Box  sx={{
                    flexGrow: 1,
                    display: { xs: 'none', md: 'flex' },
                    justifyContent: 'flex-end'
                  }}>
              {navItems.map((item) => (
                  item.visible === false ? null : (
                      <Button 
                          sx={{ color: 'white', display: 'block' }}
                          key={item.text} 
                          onClick={item.onClick}
                      >
                          {item.text}
                      </Button>
                  )
              ))}
          </Box>
      </Toolbar>
    </AppBar>
  );
}

export { TopMenu }
