import React from 'react';
import { useAPI } from '@/context/AuthProvider';
import { useAuth } from '@/context/AuthProvider';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActionArea from '@mui/material/CardActionArea';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Loading from '@/components/Loading';

const Home = () => {
    const { keycloak } = useAuth();
    const token = keycloak.tokenParsed;
    const [dashboards, isLoading, error] = useAPI(`/api/v1/metabase/dashboards/${keycloak.tokenParsed.metabase_reports_configuration}`);
    const navigate = useNavigate();

    console.log(dashboards);

    if (isLoading) return <Loading />;
    if (error) return <p>Error: {error}</p>;

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
            {token?.given_name && token?.groups ? (
                <>
                    <h1>Welcome {token.given_name}</h1>
                    <div style={{ width: '80%', marginTop: '20px' }}>
                        <h2>Your Reports</h2>
                        <Grid container spacing={3}>
                            {dashboards.map((dashboard) => (
                                <Grid item key={dashboard.id} xs={12} sm={6} md={4}>
                                    <Card variant="outlined" sx={{ borderRadius: '8px' }}>
                                        <CardActionArea onClick={() => navigate(`/dashboard/${dashboard.id}`)}>
                                            <CardContent>
                                                <Typography variant="h5" component="div">
                                                    {dashboard.name}
                                                </Typography>
                                                <Typography variant="body2" color="textSecondary">
                                                    {dashboard.description}
                                                </Typography>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </div>
                </>
            ) : null}
        </div>
    );
};

export default Home;
