import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { useParams } from 'react-router-dom';
import { useAPI } from '@/context/AuthProvider';
import Loading from '@/components/Loading';

export default function PatientEvents() {
    const { entity_id } = useParams();

    const [events, isLoading, error] = useAPI(`/api/v1/patients/${entity_id}/events`);

    if (isLoading) return <Loading />;
    if (error) return <p>Error: {error}</p>;
    if (!events) return <p>No profile data</p>;

    const columns = [
        { field: 'ext_ref', headerName: 'ext_ref', width: 300, editable: false },
        {
            field: 'data',
            headerName: 'data',
            width: 300,
            editable: false,
            renderCell: (params) => {
                const jsonObj = JSON.parse(params.value);
                const jsonStr = JSON.stringify(jsonObj, null, 2); // Pretty print JSON with 2 spaces
                return (
                    <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
                        {jsonStr}
                    </pre>
                );
            },
        },
        { field: 'created_at', headerName: 'created_at', width: 200, editable: false },
        { field: 'updated_at', headerName: 'updated_at', width: 200, editable: false },
        { field: 'type', headerName: 'type', width: 100, editable: false },
        {
            field: 'body',
            headerName: 'body',
            width: 500,
            editable: false,
            renderCell: (params) => (
                <div style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: 1.5 }}>
                    {params.value}
                </div>
            ),
        },
    ];

    const getRowHeight = (row) => {
        const lineHeight = 24;

        const bodyText = row.model.body || '';
        const lines = Math.ceil(bodyText.length / 80);
        const bodyHeight = lines * lineHeight;

        const dataJsonObj = JSON.parse(row.model.data);

        const dataText = JSON.stringify(dataJsonObj || '', null, 2);
        const newLines = (dataText.match(/\n/g) || []).length;
        const dataHeight = (newLines+2) * lineHeight;

        return Math.max(0, bodyHeight, dataHeight);
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: 'calc(100vh - 80px)', width: '100%' }}>
            <DataGrid
                rows={events}
                columns={columns}
                getRowId={(row) => row.ext_ref}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 10,
                        },
                    },
                }}
                pageSizeOptions={[10]}
                disableRowSelectionOnClick
                getRowHeight={getRowHeight}
                sx={{
                    '& .MuiDataGrid-columnHeaders': {
                        position: 'sticky',
                        top: 0,
                        zIndex: 1,
                        backgroundColor: 'white',
                    },
                    '& .MuiDataGrid-footerContainer': {
                        position: 'sticky',
                        bottom: 0,
                        zIndex: 1,
                        backgroundColor: 'white',
                    },
                    '& .MuiDataGrid-virtualScroller': {
                        marginBottom: '50px', // Adjust if needed to ensure footer is not overlapping content
                    },
                    '& .MuiDataGrid-cell': {
                        whiteSpace: 'normal',
                        wordWrap: 'break-word',
                        lineHeight: 1.5,
                    },
                }}
            />
        </Box>
    );
}
