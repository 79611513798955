import React, { useState } from 'react';
import { useAPI } from '@/context/AuthProvider';
import Button from '@mui/material/Button';
import { useAuth } from '@/context/AuthProvider';
import Loading from '@/components/Loading';

export const Profile = () => {
    const [data, isLoading, error] = useAPI('/api/v1/profile');
    const [showFullJson, setShowFullJson] = useState(false);
    const { keycloak } = useAuth();

    if (isLoading) return <Loading />;
    if (error) return <p>Error: {error}</p>;

    const { name, groups, preferred_username, email } = data;
    
    const changePassword = () => {
        const keycloakUrl = keycloak.authServerUrl;
        const realm = keycloak.realm;
        const clientId = keycloak.clientId;
        const redirectUri = encodeURIComponent(window.location.origin);
        const changePasswordUrl = `${keycloakUrl}/realms/${realm}/protocol/openid-connect/auth?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=openid&kc_action=UPDATE_PASSWORD`;
        window.location.href = changePasswordUrl;
    }

    return (
        <div style={{ textAlign: 'left' }}>
            <h1>User Data</h1>
            <p><strong>Name:</strong> {name}</p>
            <p><strong>Groups:</strong> {groups.join(', ')}</p>
            <p><strong>Preferred Username:</strong> {preferred_username}</p>
            <p><strong>Email:</strong> {email}</p>
            <Button variant="contained" color="primary" onClick={changePassword} >Change Password</Button>
            <br />
            <br />
            <Button variant="outlined" onClick={() => setShowFullJson(!showFullJson)}>
                {showFullJson ? 'Hide Full JSON token' : 'Show Full JSON token'}
            </Button>
            {showFullJson && (
                <pre>{JSON.stringify(data, null, 2)}</pre>
            )}
        </div>
    );
};

export default Profile;
