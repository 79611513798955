import { createTheme } from '@mui/material/styles';
import { lime, purple } from '@mui/material/colors';

const theme = createTheme({
    palette: {
        primary: {
            main: '#073B4C',
        },
        secondary: {
            main: '#D496A7',
            contrastText: '#000',
        },
    },
});

export default theme;
