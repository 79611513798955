import * as React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { useAPI } from '@/context/AuthProvider';
import Loading from '@/components/Loading';

export default function Page() {
    const [patients, isLoading, error] = useAPI('/api/v1/patients?limit=5000');
    const navigate = useNavigate();

    if (isLoading) return <Loading />;
    if (error) return <p>Error: {error}</p>;
    if (!patients) return <p>No profile data</p>;

    const columns = [
        { field: 'id', headerName: 'ID', width: 300 },
        {
            field: 'birth_year',
            headerName: 'Birth year',
            width: 300,
            editable: false,
        },
        {
            field: 'event_count',
            headerName: '# events',
            width: 300,
            editable: false,
        },
        {
            field: 'action',
            headerName: 'Action',
            width: 300,
            renderCell: (params) => {
                return (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => navigate(`/patients/${params.id}/events`)}
                    >
                        {`View ${params.row.event_count} events`}
                    </Button>
                );
            },
        },
    ];

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: 'calc(100vh - 80px)', width: '100%' }}>
            <DataGrid
                rows={patients}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 100,
                        },
                    },
                }}
                pageSizeOptions={[100]}
                disableRowSelectionOnClick
                sx={{
                    '& .MuiDataGrid-columnHeaders': {
                        position: 'sticky',
                        top: 0,
                        zIndex: 1,
                        backgroundColor: 'white',
                    },
                    '& .MuiDataGrid-footerContainer': {
                        position: 'sticky',
                        bottom: 0,
                        zIndex: 1,
                        backgroundColor: 'white',
                    },
                    '& .MuiDataGrid-virtualScroller': {
                        marginBottom: '50px', // Adjust if needed to ensure footer is not overlapping content
                    },
                }}
            />
        </Box>
    );
}
